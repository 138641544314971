import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatText } from '@pik/pik-utils';
import Countdown from 'on-react-countdown';

import {
  Typography,
  TextField,
} from '@pik/pik-ui';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import {
  sendCode, sendApproveCode,
} from '../../../../../../services/recovery/actions';

import {
  Wrapper,
  ResendCode,
} from './styles';

const { getPhone } = formatText;
const CountdownText = {
  days: 'дн.',
  hours: 'ч.',
  minutes: 'мин.',
  seconds: 'сек.',
};

class PasswordRecoveryCode extends Component {
  static propTypes = {
    sendCodeAction: PropTypes.func.isRequired,
    sendApproveCodeAction: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    phone: PropTypes.string,
    codeApproveError: PropTypes.string,
  };

  static defaultProps = {
    phone: null,
    codeApproveError: null,
  };

  state = {
    code: null,
    resendCode: false,
    timeResend: null,
    captcha: null,
  };

  componentDidMount() {
    const timeResend = Math.floor(new Date().getTime() / 1000) + 60;
    this.setState({ timeResend });
  }

  onChangeCode = (name, value) => {
    const { captcha } = this.state;
    const { sendApproveCodeAction, phone } = this.props;
    if (value && value.length > 4) {
      return null;
    }

    if (value && value.length === 4) {
      sendApproveCodeAction({ only_code: value, phone, captcha });
    }

    return this.setState({ [name]: value });
  };

  onChangeResendCode = () => {
    const { captcha } = this.state;
    const { sendCodeAction, phone } = this.props;
    const timeResend = Math.floor(new Date().getTime() / 1000) + 60;

    sendCodeAction({ phone, captcha });
    this.setState({ timeResend, resendCode: false });
  };

  resendCodeBlock() {
    const { resendCode, timeResend } = this.state;

    if (resendCode) {
      return (
        <ResendCode onClick={this.onChangeResendCode}>
          <Typography type="caption" color="secondaryMedium">
            Отправить код повторно
          </Typography>
        </ResendCode>
      );
    }

    if (!resendCode && timeResend) {
      return (
        <Typography type="caption" color="grayLight">
          Запросить код повторно можно через
          { ' ' }
          <Countdown
            end={timeResend}
            onEnd={() => this.setState({ resendCode: true })}
            text={CountdownText}
            wordsEndingOff
            limit={1}
          />
        </Typography>
      );
    }

    return null;
  }

  render() {
    const { phone, loading, codeApproveError } = this.props;
    const { code } = this.state;

    return (
      <GoogleReCaptchaProvider reCaptchaKey="6Lfj9woqAAAAAHCgjvsbm5LA0MR_yA4uCDI5gmJH">
        <Typography type="body" color="grayDark">
          Введите код подтверждения отправленный на номер
          { ' ' }
          { phone && getPhone(phone) }
        </Typography>
        <Wrapper>
          <TextField
            type="number"
            value={code}
            disabled={loading}
            error={codeApproveError || ''}
            name="code"
            placeholder="Код подтверждения"
            inputStyle="accent"
            onChange={this.onChangeCode}
          />
        </Wrapper>
        <Wrapper>
          { this.resendCodeBlock() }
        </Wrapper>
        <GoogleReCaptcha onVerify={this.handleReCaptcha} />
      </GoogleReCaptchaProvider>
    );
  }
}

const mapStateToProps = ({ recoveryService }) => ({
  phone: recoveryService.phone,
  authKey: recoveryService.authKey,
  loading: recoveryService.loading,
  codeApproveError: recoveryService.codeApproveError,
});

const mapDispatchToProps = {
  sendCodeAction: sendCode,
  sendApproveCodeAction: sendApproveCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecoveryCode);
